<h2 class="rkt-Heading-20 rkt-FontWeight--500 rkt-mb3" mat-dialog-title>
    Confirm Delete
</h2>

<p class="rkt-Body-16 rkt-mb4 rkt-Color--gray-600">
    Are you sure you want to delete the building <strong>{{ data.building }}</strong>?
</p>

<rkt-stack itemSpacing="3" additionalClasses="rkt-Stack--center-column rkt-Stack--end-row" splitAt="1">
    <button rktStackItem mat-button class="rkt-Button rkt-Button--secondary rkt-Button--large" color="accent"
        mat-dialog-close (click)="onNoClick()">
        Cancel
    </button>

    <button rktStackItem mat-flat-button class="rkt-Button rkt-Button--large" color="primary"
        (click)="onConfirmClick()">
        Confirm
    </button>
</rkt-stack>
