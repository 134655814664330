import { Injectable, Inject } from '@angular/core';
import { IAdminService } from './admin.service.interface';
import { BehaviorSubject, Observable, empty, EMPTY } from 'rxjs';
import { IPawsApiService } from '../paws-api/paws-api.interface';
import { UserNotificationService } from '../user-notification/user-notification.service';
import { IUniqueIdentifier } from 'src/app/models/uniqueIdentifier';
import { map } from 'rxjs/operators';
import { map as lodashMap } from 'lodash';
import { BuildingFloor } from 'src/app/models/location';
import { orderBy } from 'lodash';
import { environment } from 'src/environments/environment';
import { BuildingAction, UpdateBuildingFloor } from 'src/app/models/UpdateBuildingFloor';

@Injectable()
export class AdminService implements IAdminService {
    public LocationData: BuildingFloor[];
    private _isLoading: BehaviorSubject<boolean>;
    public isLoading$: Observable<boolean>;

    constructor(
        @Inject('PawsApiService') private pawsApi: IPawsApiService,
        private notificationService: UserNotificationService
    ) {
        this._isLoading = new BehaviorSubject<boolean>(false);
        this.isLoading$ = this._isLoading.asObservable();
        this.LocationData = [];
    }

    public get isLoading(): boolean {
        return this._isLoading.value;
    }

    public async getBuildingFloor() {
        try {
            this._isLoading.next(true);
            this.LocationData = await this.pawsApi.get<BuildingFloor[], IUniqueIdentifier>(
              environment.api.pawsApi.url + '/api/getBuildingFloor', null)
                .pipe(
                    map(response => {
                        const locations = lodashMap(response, (item) => {
                            const building = Object.assign(new BuildingFloor(), item);
                            building.CreateDate = new Date(building.SortKey.split('_')[1]);
                            return building;
                        });

                        return orderBy(
                            locations,
                            (request) => [request.Building, new Date(request.Building)], ['asc', 'desc']
                        );
                    })
                ).toPromise();
        } catch (err) {
            this.notificationService.handleError(err);
        } finally {
            this._isLoading.next(false);
        }
    }

    public async updateBuildingFloors(building: string, floorsList: string[]) {
        try {
            this._isLoading.next(true);

            await this.pawsApi.post<UpdateBuildingFloor>(
                environment.api.pawsApi.url + '/api/manageBuildingFloor',
                {
                    action: BuildingAction.Add,
                    building,
                    floor: floorsList.reduce((acc: { [key: string]: string }, floor: string) => {
                        acc[floor] = floor;
                        return acc;
                    }, {}),
                }
            ).toPromise();
            this.notificationService.addSuccessMessage(`Floors successfully updated for ${building}.`);
        } catch (err) {
            this.notificationService.handleError(err);
        } finally {
            this._isLoading.next(false);
        }
    }

    public async addBuilding(building: string) {
        try {
            this._isLoading.next(true);

            await this.pawsApi.post<UpdateBuildingFloor>(
                environment.api.pawsApi.url + '/api/manageBuildingFloor',
                {
                    action: BuildingAction.Add,
                    building,
                    floor: {},
                }
            ).toPromise();
            this.notificationService.addSuccessMessage(`Building ${building} successfully added.`);
        } catch (err) {
            this.notificationService.handleError(err);
        } finally {
            this._isLoading.next(false);
        }
    }

    public async deleteBuilding(building: string) {
        try {
            this._isLoading.next(true);

            await this.pawsApi.post<UpdateBuildingFloor>(
                environment.api.pawsApi.url + '/api/manageBuildingFloor',
                {
                    action: BuildingAction.Delete,
                    building,
                }
            ).toPromise();
            this.notificationService.addSuccessMessage(`Building ${building} successfully deleted.`);
        } catch (err) {
            this.notificationService.handleError(err);
        } finally {
            this._isLoading.next(false);
        }
    }
}
