
<div class="dropdown" *ngIf="showProfileDropdown">
  <button mat-button [matMenuTriggerFor]="menu" class="rkt-Label-16">
    <img [src]="teamThumbnailSrc" alt="Team Member Thumbnail" />
  </button>

  <mat-menu #menu="matMenu" class="rkt-Menu">
    <a routerLink="/tm-dashboard" routerLinkActive="active" id="tmdashboard">
      <button mat-menu-item class="rkt-Menu__item">My Dashboard</button>
    </a>
    <a *ngIf="showLeaderLinks" routerLink="/tl-dashboard" routerLinkActive="active" id="tldashboard">
      <button mat-menu-item class="rkt-Menu__item">My Team Dashboard</button>
    </a>
    <a *ngIf="showAdminLinks" routerLink="/admin-request-dashboard" routerLinkActive="active" id="adminRegistrationDashboard">
      <button mat-menu-item class="rkt-Menu__item">Admin Application Dashboard</button>
    </a>
    <a *ngIf="showAdminLinks" routerLink="/admin-schedule-dashboard" routerLinkActive="active" id="adminScheduleDashboard">
      <button mat-menu-item class="rkt-Menu__item">Admin Schedule Dashboard</button>
    </a>
    <a *ngIf="showAdminLinks" routerLink="/admin-building-dashboard" routerLinkActive="active" id="adminBuildingDashboard">
      <button mat-menu-item class="rkt-Menu__item">Admin Building Dashboard</button>
    </a>
    <a *ngIf="showImpersonationLink" routerLink="/impersonation" routerLinkActive="active">
      <button mat-menu-item class="rkt-Menu__item">Impersonation</button>
    </a>
  </mat-menu>
</div>
