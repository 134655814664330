export enum BuildingAction {
    Add = 'Add',
    Delete = 'Delete',
}

export class UpdateBuildingFloor {
    action: BuildingAction;
    building: string;
    floor?: { [key: string]: string };
}
