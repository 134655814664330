<app-jumbotron class="m-auto" header="Admin Building Dashboard">
</app-jumbotron>
<div class="container rkt-CenteredColumn">
  <form>
    <h2>Update Building</h2>
    <p>
      <strong>Please note:</strong> There is no building or floor validation.
      Ensure that the building and floor(s) are correct before updating. Visit
      <a
        href="https://rockfoc.iofficeconnect.com/home.i#/space/spacedesktop?mode=information"
        target="_blank"
        rel="noopener"
        >iOffice</a
      >
      for building information.
    </p>

    <div class="row">
      <div class="col-6" *ngIf="buildings">
        <label for="BuildingSelect" class="required-field">Building Name</label>
        <select
          id="BuildingSelect"
          name="BuildingSelect"
          class="form-control"
          [(ngModel)]="building"
          (change)="onBuildingChange()"
          aria-label="Select a building"
          required
        >
          <option [value]="item.key" *ngFor="let item of buildings">
            {{ item.value }}
          </option>
        </select>
      </div>

      <div class="d-flex align-end">
        <button
          class="btn btn-secondary btn-height rkt-ml3"
          [disabled]="!building"
          type="button"
          (click)="openDeleteBuildingDialog(building)"
          aria-label="Remove selected building"
        >
          Remove Building
        </button>
      </div>
    </div>

    <div class="chip-list-width rkt-pv3">
      <mat-chip-list aria-labelledby="List of floors">
        <mat-chip
          *ngFor="let floor of floors"
          class="rkt-Chip"
          removable="true"
          (removed)="removeFloor(floor)"
        >
          {{ floor }}
          <button matChipRemove aria-label="Remove floor {{ floor }}">
            <mat-icon color="accent" svgIcon="cancel-outlined"></mat-icon>
          </button>
        </mat-chip>
      </mat-chip-list>
    </div>

    <div class="row">
      <div class="col-6">
        <label for="AddFloor">Floor(s)</label>
        <input
          id="AddFloor"
          name="AddFloor"
          type="text"
          [disabled]="!building"
          class="form-control"
          [(ngModel)]="floor"
          placeholder="Enter a floor"
          aria-label="Enter a floor to add"
        />
      </div>
      <div class="d-flex align-end">
        <button
          class="btn btn-secondary btn-height rkt-ml3"
          [disabled]="!floor?.trim()"
          (click)="addFloor(floor, building); floor = ''"
          aria-label="Add floor"
        >
          Add Floor
        </button>
      </div>
    </div>

    <div class="d-flex align-items-center rkt-mv5">
      <button
        class="btn btn-primary"
        [disabled]="!building || !floors.length"
        type="submit"
        (click)="updateBuildingFloors(building, floors)"
        aria-label="Update floors for selected building"
      >
        Update
      </button>
      <i class="rkt-pl3">Click 'Update' to save floor changes!</i>
    </div>

    <mat-divider class="rkt-HorizontalDivider rkt-pb5"></mat-divider>

    <h2>Add Building</h2>
    <div class="row">
      <div class="col-6">
        <label for="Building" class="required-field">Building</label>
        <input
          id="Building"
          name="Building"
          type="text"
          class="form-control"
          [(ngModel)]="newBuilding"
          placeholder="Enter a building name"
          aria-label="Enter a building name to add"
          required
        />
      </div>

      <div class="d-flex align-end">
        <button
          class="btn btn-primary btn-height rkt-ml3"
          [disabled]="!newBuilding.trim()"
          (click)="addBuilding(newBuilding.trim())"
          aria-label="Add building"
        >
          Add Building
        </button>
      </div>
    </div>
  </form>
</div>
