import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import { AgGridModule } from 'ag-grid-angular';
import {
  AuthHttpInterceptor,
  AuthModule,
  AuthService,
} from '@auth0/auth0-angular';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ProfileComponent } from './components/profile/profile.component';
import { HomePageComponent } from './components/homepage/homepage.component';
import { ScheduleFormComponent } from './components/schedule-form/schedule-form.component';
import {
  NgbModule,
  NgbDateNativeAdapter,
  NgbDateAdapter,
} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { PawsRegistrationComponent } from './components/paws-registration/paws-registration.component';
import { TmAcknowledgmentComponent } from './components/tm-acknowledgment/tm-acknowledgment.component';
import { TmAutopopulateComponent } from './components/tm-autopopulate/tm-autopopulate.component';
import { DogVaccineuploadComponent } from './components/dog-vaccineupload/dog-vaccineupload.component';
import { DogInformationComponent } from './components/dog-information/dog-information.component';
import { DocumentUploadComponent } from './components/custom/document-upload/document-upload.component';
import { UserNotificationComponent } from './components/custom/user-notification/user-notification.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { RegistrationService } from './services/registration/registration.service';
import { UserNotificationService } from './services/user-notification/user-notification.service';
import { environment } from '../environments/environment';
import { CalendarComponent } from './components/custom/calendar/calendar.component';
import { TmDashboardComponent } from './components/tm-dashboard/tm-dashboard.component';
import { TlDashboardComponent } from './components/tl-dashboard/tl-dashboard.component';
import { AdminRequestDashboardComponent } from './components/admin-request-dashboard/admin-request-dashboard.component';
import { AdminBuildingDashboardComponent } from './components/admin-building-dashboard/admin-building-dashboard.component';
import { GridComponent } from './components/custom/grid/grid.component';
import { JumbotronComponent } from './components/custom/jumbotron/jumbotron.component';
import { DatePickerFormGroupComponent } from './components/custom/date-picker-form-group/date-picker-form-group.component';
import { RedirectToUpdateDetailComponent } from './components/custom/redirect-to-update-detail/redirect-to-update-detail.component';
import { PulseAdminSectionComponent } from './components/pulse-admin-section/pulse-admin-section.component';
import { UpdateRegistrationDetailsComponent } from './components/update-registration-details/update-registration-details.component';
import { DocumentDownloadComponent } from './components/document-download/document-download.component';
import { RegistrationRequestSearchService } from './services/search/registration-request-search.service';
import { StatusFormGroupComponent } from './components/custom/status-form-group/status-form-group.component';
import { GlobalErrorHandlerService } from './services/error-handler/global-error-handler.service';
import { AdminScheduleDashboardComponent } from './components/admin-schedule-dashboard/admin-schedule-dashboard.component';
import { DatePipe } from '@angular/common';
import { UpdateScheduleDetailsComponent } from './components/update-schedule-details/update-schedule-details.component';
import { BuildingFloorSectionComponent } from './components/custom/building-floor-section/building-floor-section.component';
import { ImpersonationComponent } from './components/impersonation/impersonation.component';
import { CurrentUserService } from './services/current-user/current-user.service';
import { ScheduleInformationComponent } from './components/schedule-information/schedule-information.component';
import { ScheduleRequestSearchService } from './services/search/schedule-request-search.service';
import { TlApprovalSectionComponent } from './components/tl-approval-section/tl-approval-section.component';
import { AdminService } from './services/admin/admin.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PawsAuthService } from './auth/paws-auth-service';
import { PawsApiService } from './services/paws-api/paws-api.service';
import { AuthenticationGuard, AuthorizationGuard } from './auth';
import { MatDialogModule } from '@angular/material/dialog';
import { DedicatedDeskConfirmComponent } from './components/modals/dedicated-desk-confirm/dedicated-desk-confirm.component';
import { DeleteBuildingConfirmComponent } from './components/modals/delete-building-confirm/delete-building-confirm.component';
import { RocketDesignSystemModule } from '@rocketcentral/rocket-design-system-angular';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ProfileComponent,
    HomePageComponent,
    ScheduleFormComponent,
    PawsRegistrationComponent,
    TmAcknowledgmentComponent,
    TmAutopopulateComponent,
    DogVaccineuploadComponent,
    DogInformationComponent,
    DocumentUploadComponent,
    UserNotificationComponent,
    CalendarComponent,
    TmDashboardComponent,
    TlDashboardComponent,
    AdminRequestDashboardComponent,
    GridComponent,
    JumbotronComponent,
    RedirectToUpdateDetailComponent,
    DatePickerFormGroupComponent,
    PulseAdminSectionComponent,
    UpdateRegistrationDetailsComponent,
    DocumentDownloadComponent,
    StatusFormGroupComponent,
    AdminScheduleDashboardComponent,
    AdminBuildingDashboardComponent,
    BuildingFloorSectionComponent,
    ImpersonationComponent,
    UpdateScheduleDetailsComponent,
    ScheduleInformationComponent,
    TlApprovalSectionComponent,
    DedicatedDeskConfirmComponent,
    DeleteBuildingConfirmComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    HttpClientModule,
    RocketDesignSystemModule,
    MatDialogModule,
    // internal
    AuthModule.forRoot({
      domain: environment.auth.domain,
      clientId: environment.auth.clientId,
      audience: environment.auth.audience,
      redirectUri: environment.auth.redirect,
      errorPath: '/',
      useRefreshTokens: true,
      httpInterceptor: {
        allowedList: [environment.api.pawsApi.url + '/*'],
      },
    }),
    // external
    FullCalendarModule,
    AgGridModule.withComponents([RedirectToUpdateDetailComponent]),
  ],
  providers: [
    AuthenticationGuard,
    AuthorizationGuard,
    CurrentUserService,
    RegistrationService,
    AdminService,
    UserNotificationService,
    { provide: 'PawsAuthService', useClass: environment.authServiceType },
    { provide: 'PawsApiService', useClass: environment.apiServiceType },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: 'AuthService', useClass: AuthService },
    { provide: 'AdminSchedule', useClass: ScheduleRequestSearchService },
    { provide: 'AdminRegistration', useClass: RegistrationRequestSearchService },
    { provide: 'Schedule', useClass: ScheduleRequestSearchService },
    { provide: 'Registration', useClass: RegistrationRequestSearchService },
    { provide: 'TLRegistration', useClass: RegistrationRequestSearchService },
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
