import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IDropDownData } from 'src/app/models/common';
import { AdminService } from 'src/app/services/admin/admin.service';
import { BuildingFloor } from 'src/app/models/location';
import { filter } from 'lodash';
import { UserNotificationService } from 'src/app/services/user-notification/user-notification.service';
import { DeleteBuildingConfirmComponent } from '../modals/delete-building-confirm/delete-building-confirm.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-admin-building-dashboard',
  templateUrl: './admin-building-dashboard.component.html',
  styleUrls: ['./admin-building-dashboard.component.scss'],
})
export class AdminBuildingDashboardComponent implements OnInit {
  @Output() public buildingChange = new EventEmitter();

  public building: string;
  public floor: string;

  public newBuilding: string;
  public buildings: IDropDownData[];
  public floors: string[];

  public buildingFloorsList: BuildingFloor[];

  constructor(public dialog: MatDialog, private adminService: AdminService, private notificationService: UserNotificationService) {}

  ngOnInit() {
    this.buildings = [];
    this.resetInputs();
    this.onBuildingChange();
  }

  public openDeleteBuildingDialog(building: string): void {
    const dialogRef = this.dialog.open(DeleteBuildingConfirmComponent, {
      panelClass: 'rkt-Dialog',
      data: { building },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.deleteBuilding(building);
      }
    });
  }

  public async getLocationData() {
    await this.adminService.getBuildingFloor();
    this.buildingFloorsList = this.adminService.LocationData;
    this.buildings = [];
    this.buildingFloorsList.forEach((item) => {
      this.buildings.push({ key: item.Building, value: item.Building });
    });
  }

  public async onBuildingChange() {
    this.floors = [];

    if (!this.buildingFloorsList) {
      await this.getLocationData();
    }

    if (this.building && this.buildingFloorsList) {
      const selectedBuilding = filter(this.buildingFloorsList, {
        Building: this.building,
      });

      if (selectedBuilding) {
        for (const key of Object.keys(selectedBuilding[0].Floor)) {
          this.floors.push(key);
        }
        this.floors.sort((n1, n2) =>
          parseInt(n1, 10) > parseInt(n2, 10) ? 1 : -1
        );
        this.buildingChange.emit(this.building);
      }
    }

    // reset the floor value if its not in the floor list
    if (this.floors.filter((x) => x === this.floor).length === 0) {
      this.floor = null;
    }
  }

  public addFloor(floor: string, building: string): void {
    const trimmedFloor = floor.trim();

    // Check if the floor is already in the list
    if (this.floors.includes(trimmedFloor)) {
      this.notificationService.addMessage(`Floor ${trimmedFloor} already exists for ${building}.`, 2);
      return;
    }

    // Add the floor and sort the list
    this.floors.push(trimmedFloor);
    this.sortFloors();
  }

  public removeFloor(floor: string): void {
    const index = this.floors.indexOf(floor);
    if (index >= 0) {
      this.floors.splice(index, 1);
    }
  }

  public async updateBuildingFloors(building: string, floorsList: string[]): Promise<void> {
    await this.adminService.updateBuildingFloors(building, floorsList);
    await this.getLocationData(); // Refresh dropdown
    this.resetInputs();
  }

  public async addBuilding(building: string): Promise<void> {
    const buildingExists = this.buildings.some((b) => b.value === building);

    if (buildingExists) {
      this.notificationService.addMessage(`Building not added. ${building} already exists.`, 2);
      this.resetInputs();
      return;
    }

    await this.adminService.addBuilding(building);
    await this.getLocationData(); // Refresh dropdown
    this.resetInputs();
  }

  public async deleteBuilding(building: string): Promise<void> {
    await this.adminService.deleteBuilding(building);
    await this.getLocationData(); // Refresh dropdown
    this.resetInputs();
  }

  public resetInputs(): void {
    this.building = null;
    this.floors = [];
    this.floor = '';
    this.newBuilding = '';
  }

  private sortFloors(): void {
    this.floors.sort((a, b) => {
      const aIsNumber = !isNaN(parseInt(a, 10));
      const bIsNumber = !isNaN(parseInt(b, 10));

      if (aIsNumber && bIsNumber) {
        return parseInt(a, 10) - parseInt(b, 10); // Numeric comparison
      }

      if (aIsNumber) {
        return -1; // Numbers come before strings
      }

      if (bIsNumber) {
        return 1; // Strings come after numbers
      }

      return a.localeCompare(b); // Alphabetical comparison for strings
    });
  }
}
